import React from 'react'
import styled from 'styled-components'

import Logo from './Logo'
import Links from './Links'

const Container = styled.div.attrs({
    className: 'container',
})`
    height: 150px;
`

const Nav = styled.nav.attrs({
    className: 'navbar navbar-expand-lg navbar-dark bg-dark',
})`
    margin-bottom: 20 px;
`

function NavBar(props) {
    return (
      <Container>
        <Nav>
          <Logo />
          <Links isLoggedIn={props.isLoggedIn} doLogin={props.doLogin} doLogout={props.doLogout} />
        </Nav>
      </Container>
    )
}

export default NavBar
