import React, { Component } from 'react'
// eslint-disable-next-line
import { BrowserRouter as Router, Link } from 'react-router-dom'
import api from '../api'

import styled from 'styled-components'

const Wrapper = styled.div`
    padding: 1em 2.2em 2.2em 2.2em;
    margin: 0 auto;
`

const Card = styled.div`
    margin: 0.3em 0.7em;
`

const SpaceWrapper = styled.div`
    text-align: center;
    margin-top: 1em;
`

class Consultation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            oeuvres: [],
            search: '',
            isLoading: false,
        }
    }

    componentDidMount = async () => {
        this.setState({ isLoading: true })

        await api.getAllOeuvres().then(oeuvres => {
            this.setState({
                oeuvres: oeuvres.data.data,
                isLoading: false,
            })
        })
    }

    setSearch = async (text) => {
        if (text === '') {
            await api.getAllOeuvres().then(oeuvres => {
                this.setState({
                    search: text,
                    oeuvres: oeuvres.data.data,
                })
            })
        } else {
            await api.getOeuvres(text).then(oeuvres => {
                this.setState({
                    search: text,
                    oeuvres: oeuvres.data.data,
                })
            })
        }
    }

    render() {
      if (this.state.isLoading) {
        return null;
      } else {
        return (
          <Wrapper className="container accordion" id="listAccordion">
            <div style={{textAlign: 'right', marginBottom: '1em'}}>
              <input type="text" placeholder="Rechercher" onChange={e => this.setSearch(e.target.value)} value={this.state.search} />
            </div>
            {this.state.oeuvres.length > 0
             ?  this.state.oeuvres.map((v, index) =>
              <div className="card" key={index}>
                <div className="card-header" id={`heading_${index}`}>
                  <h2 className="mb-0">
                    <button className="btn btn-link btn-block text-left" type="button" aria-expanded="true" onClick={e => document.getElementById(`collapse_${index}`).classList.toggle("show")}>
                      {v.auteur} &nbsp;&mdash;&nbsp; {v.titre}
                    </button>
                  </h2>
                </div>
                <div className="collapse" id={`collapse_${index}`}>
                  <Card className="card-body">
                    <p><strong>Date de première publication de l'&oelig;uvre</strong>: {v.datePublication.join(', ')}</p>
                    <p><strong>URL de consultation / téléchargement</strong>: <a href={v.urlConsultation.trim()} target="_blank" rel="noopener noreferrer">{v.urlConsultation}</a></p>
                    <SpaceWrapper><Link to={`/fiche/${v._id}`}><button type="button" className="btn btn-dark">Visualiser la fiche</button></Link></SpaceWrapper>
                  </Card>
                </div>
              </div>
            ) : <SpaceWrapper>Aucune &oelig;uvre trouvée.</SpaceWrapper>}
          </Wrapper>
        )
      }
    }
}

export default Consultation
