import React, { Component } from 'react'
// eslint-disable-next-line
import { BrowserRouter as Router, Link } from 'react-router-dom'
import ReactTable from 'react-table'
import api from '../api'

import styled from 'styled-components'

import 'react-table/react-table.css'

const Wrapper = styled.div`
    padding: 0 40px 40px 40px;
    max-width: 80em;
    margin: 0 auto;
`

const Update = styled.div`
    color: #ef9b0f;
    cursor: pointer;
    text-decoration: none;
`

const Delete = styled.div`
    color: #ff0000;
    cursor: pointer;
`

class DeleteOeuvre extends Component {
    deleteUser = event => {
        event.preventDefault()

        if (
            window.confirm(
                `Voulez-vous vraiment supprimer l'oeuvre « ${this.props.titre} »?`,
            )
        ) {
            api.deleteOeuvreById(this.props.id)
            window.location.reload()
        }
    }

    render() {
        return <Delete onClick={this.deleteUser}>Supprimer</Delete>
    }
}

class OeuvresList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            oeuvres: [],
            columns: [],
            isLoading: false,
        }
    }

    componentDidMount = async () => {
        this.setState({ isLoading: true })

        await api.getAllOeuvres().then(oeuvres => {
            this.setState({
                oeuvres: oeuvres.data.data,
                isLoading: false,
            })
        })
    }

    render() {
        const { oeuvres, isLoading } = this.state
        console.log('TCL: OeuvresList -> render -> oeuvres', oeuvres)

        const columns = [
            {
                Header: 'Date création',
                accessor: 'dateCreation',
                maxWidth: 120,
                filterable: true,
                Cell: (props) => props.original.dateCreation.substring(0,10)
            },
            {
                Header: 'Titre',
                accessor: 'titre',
                filterable: true,
            },
            {
                Header: 'Auteur',
                accessor: 'auteur',
                filterable: true,
            },
            {
                Header: 'Langue(s)',
                accessor: 'langues',
                filterable: false,
                Cell: (p) => p.original.langues.concat(p.original.autres_langues).join(', ')
            },
            {
                Header: 'Genre(s)',
                accessor: 'genres',
                filterable: false,
                Cell: (p) => p.original.genres.map(v => v.key).join(', ')
            },
            {
                Header: '',
                accessor: '',
                maxWidth: 90,
                Cell: function(props) {
                    return (
                        <Link to={`/oeuvres/update/${props.original._id}`}>
                            <Update>Modifier</Update>
                        </Link>
                    )
                },
            },
            {
                Header: '',
                accessor: '',
                maxWidth: 100,
                Cell: function(props) {
                    return (
                        <span>
                            <DeleteOeuvre id={props.original._id} titre={props.original.titre} />
                        </span>
                    )
                },
            },
        ]

        let showTable = true
        if (!oeuvres.length) {
            showTable = false
        }

        return (
            <Wrapper>
                {showTable && (
                    <ReactTable
                        data={oeuvres}
                        columns={columns}
                        loading={isLoading}
                        defaultPageSize={10}
                        showPageSizeOptions={true}
                        minRows={0}
                        previousText='Précédent'
                        nextText='Suivant'
                        ofText='de'
                        rowsText='lignes'
                    />
                )}
                <br /><p><a href="/backapi/api/download">Télécharger une copie de la base de données</a></p>
            </Wrapper>
        )
    }
}

export default OeuvresList
