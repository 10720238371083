import React, { Component } from 'react'
import api from '../api'
import OeuvreForm from './OeuvreForm'

const OeuvreVide = {
    dateCreation: (new Date().toISOString()).substring(0,10),
    createur: '',
    titre: '',
    auteur: '',
    autres_contributeurs: '',
    langues: ['Français'],
    autres_langues: [],
    editeur: '',
    serie: '',
    support: [],
    autre_support: '',
    technologies: '',
    distribution: '',
    licence: '',
    datePublication : [], 
    dateDisparition: '',
    urlConsultation: '',
    urlArchivage: '',
    descriptionExistante: '',
    maDescription: '',
    commentaire: '',
    themes: '',
    genres: [],
    genre_declare: '',
    caracteristiques: [],
    autre_caracteristique: '',
    interactions: [],
    autre_interaction: '',
    extraits: [],
    citations: [],
    personnesConsultees: [],
    articlesPresse: [],
    articles_livres: [],
    conferences: [],
    liensRepertoires: [],
    reeditions: [],
    autres: []
}


class OeuvresInsert extends Component {
    constructor(props) {
        super(props)

        this.state = OeuvreVide
    }

    changeHandler = (event) => {
        let name = event.target.name;
        let val = event.target.value;
        this.setState({[name]: val});
    }
    
    boolChangeHandler = (event) => {
        let name = event.target.name;
        let val = event.target.checked;
        this.setState({[name]: val});
    }
    
    arrayChangeHandler = (name, val) => {
        this.setState({[name]: val});
    }

    handleIncludeOeuvre = async () => {
        const payload = this.state

        await api.insertOeuvre(payload).then(res => {
            window.alert(`Oeuvre ajoutée`)
            this.setState(OeuvreVide)
        }).catch(error => {
            window.alert(`Impossible d'ajouter l'oeuvre\n\nAssurez-vous que les champs obligatoires ont été remplis.`)
        })
    }

    render() {
        return (
            <OeuvreForm
                title="Ajouter une &oelig;uvre"
                oeuvre={this.state}
                changeHandler={this.changeHandler}
                boolChangeHandler={this.boolChangeHandler}
                arrayChangeHandler={this.arrayChangeHandler}
                onSubmit={this.handleIncludeOeuvre}
                submitButtonText="Ajouter"
            />
        )
    }
}

export default OeuvresInsert
