import React, { Component } from 'react'
import api from '../api'
import OeuvreForm from './OeuvreForm'


class OeuvresUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            oeuvre: null
        }
    }

    changeHandler = (event) => {
        let name = event.target.name;
        let val = event.target.value;
        let oeuvre = this.state.oeuvre;
        oeuvre[name] = val;
        this.setState({oeuvre: oeuvre});
    }
    
    boolChangeHandler = (event) => {
        let name = event.target.name;
        let val = event.target.checked;
        let oeuvre = this.state.oeuvre;
        oeuvre[name] = val;
        this.setState({oeuvre: oeuvre});
    }
    
    arrayChangeHandler = (name, val) => {
        let oeuvre = this.state.oeuvre;
        oeuvre[name] = val;
        this.setState({oeuvre: oeuvre});
    }

    handleUpdateOeuvre = async () => {
        const { id, oeuvre } = this.state

        await api.updateOeuvreById(id, oeuvre).then(res => {
            window.alert(`Oeuvre modifiée avec succès`)
            window.location.replace('/oeuvres/list')
        }).catch(error => {
            window.alert(`Impossible de modifier l'oeuvre\n\nAssurez-vous que les champs obligatoires ont été remplis.`)
        })
    }

    componentDidMount = async () => {
        const { id } = this.state
        await api.getOeuvreById(id).then(res => {
           
            this.setState({
                oeuvre: res.data.data
            })
        })
    }

    render() {
      if (this.state.oeuvre === null) { 
        return (<div style={{textAlign: 'center'}}>Chargement...</div>);
      } else {
        return (
            <OeuvreForm
                title="Modifier une &oelig;uvre"
                oeuvre={this.state.oeuvre}
                changeHandler={this.changeHandler}
                arrayChangeHandler={this.arrayChangeHandler}
                boolChangeHandler={this.boolChangeHandler}
                onSubmit={this.handleUpdateOeuvre}
                submitButtonText="Modifier"
            />
        )
      }
    }
}

export default OeuvresUpdate
