import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown'
import ReactPlayer from 'react-player'
import api from '../api'
// eslint-disable-next-line
import { BrowserRouter as Router, Link } from 'react-router-dom'


import styled from 'styled-components'

const Title = styled.h1.attrs({
  className: 'h1',
})`
    text-align: center;
    margin: 0.5em;
`

const Author = styled.h2.attrs({
  className: 'h2',
})`
    text-align: center;
    margin-bottom: 1.5em;
`

const SectionTitle = styled.h3.attrs({
  className: 'h3',
})`
    margin: 30px 0 15px 0;
`

const SubsectionTitle = styled.h4.attrs({
  className: 'h4',
})`
    margin: 20px 0 15px 0;
`

const ButtonWrapper = styled.div`
    text-align: center;
    margin: 2em 0;
`

const Extrait = styled.div`
    margin-bottom: 3em;
`

const ImgWrapper = styled.img`
    display: block;
    margin: 0 auto;
    max-width: 95%;
`


class Fiche extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: this.props.match.params.id,
      oeuvre: null
    }
  }

  componentDidMount = async () => {
    const { id } = this.state
    await api.getOeuvreById(id).then(res => {

      this.setState({
        oeuvre: res.data.data
      })
    })
  }

  renderLine(title, name, isMarkdown = false) {
    if (this.state.oeuvre[name] !== undefined && this.state.oeuvre[name].trim() !== '') {
      return <p><strong>{title}</strong>: {isMarkdown ? <ReactMarkdown source={this.state.oeuvre[name]} linkTarget="_blank" /> : this.state.oeuvre[name]}</p>
    } else {
      return null;
    }
  }

  renderUrl(title, name) {
    if (this.state.oeuvre[name] !== undefined && this.state.oeuvre[name].trim() !== '') {
      return <p><strong>{title}</strong>: <a href={this.state.oeuvre[name]} target="_blank" rel="noopener noreferrer">{this.state.oeuvre[name]}</a></p>
    } else {
      return null;
    }
  }

  renderList(title, name, name_other = null, isMarkdown = false) {
    let list = name_other !== null ?
      (Array.isArray(this.state.oeuvre[name_other])
        ? this.state.oeuvre[name].concat(this.state.oeuvre[name_other])
        : (this.state.oeuvre[name_other].trim() !== '' ?
          this.state.oeuvre[name].concat([this.state.oeuvre[name_other]])
          : this.state.oeuvre[name])
      )
      : this.state.oeuvre[name];

    if (list.length > 0) {
      if (isMarkdown) {
        return <div><p><strong>{title}</strong>:</p> {list.map(e => <ReactMarkdown source={e} linkTarget="_blank" />)}</div>
      } else {
        return <p><strong>{title}</strong>: {list.map(e => typeof (e) === "string" ? e : (e.details !== undefined && e.details.trim() !== '' ? `${e.key} (${e.details})` : e.key)).join(', ')}</p>
      }
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className="container">
        <div style={{ display: 'inline-block', width: '100%' }}>
          <div style={{ float: 'left' }}><Link to="/">&lt; Retour à l'accueil</Link></div>
          {this.props.editButton ?
            <div style={{ float: 'right', textAlign: 'right' }}><Link className="btn btn-secondary" to={`/oeuvres/update/${this.state.id}`}>Modifier</Link></div>
            : null}
        </div>
        {this.state.oeuvre === null ?
          <div style={{ textAlign: 'center' }}>Chargement...</div>
          :
          <div>
            <Title>{this.state.oeuvre.titre}</Title>
            <Author>{this.state.oeuvre.auteur}</Author>
            <p><strong>Date de création de la fiche</strong>: {this.state.oeuvre.dateCreation.substring(0, 10)}</p>
            <p><strong>Date de mise à jour de la fiche</strong>: {new Date(this.state.oeuvre.updatedAt).toISOString().split('T')[0]}</p>
            {this.renderLine('Fiche créée par', 'createur')}

            <SectionTitle>Identification de l'&oelig;uvre</SectionTitle>
            {this.renderLine('Titre', 'titre')}
            {this.renderLine('Auteur / créateur', 'auteur')}
            {this.renderLine('Autres contributeurs', 'autres_contributeurs')}

            <SectionTitle>Informations éditoriales</SectionTitle>
            {this.renderList('Langue(s)', 'langues', 'autres_langues')}
            {this.renderLine('Éditeur', 'editeur')}
            {this.renderLine('Série', 'serie')}
            {this.renderList('Support', 'support', 'autre_support')}
            {this.renderLine('Précisions sur le support', 'precisions_support')}
            {this.renderLine('Technologies utilisées et coquille logiciel', 'technologies')}
            {this.renderLine('Distribution', 'distribution')}
            {this.renderLine('Licence', 'licence')}
            {this.renderList('Date de la première publication / date de l’événement / date de publications successives', 'datePublication')}
            {this.renderLine('Date de disparition / d’obsolescence', 'dateDisparition')}
            {this.renderUrl('URL de consultation / téléchargement', 'urlConsultation')}
            {this.renderUrl('URL d\'archivage', 'urlArchivage')}

            <SectionTitle>Contenu et forme de l’&oelig;uvre</SectionTitle>
            {this.renderLine('Description existante', 'descriptionExistante', true)}
            {this.renderLine('Ma description', 'maDescription', true)}
            {this.renderLine('Commentaire', 'commentaire', true)}
            {this.renderLine('Principaux thèmes', 'themes')}
            {this.renderList('Genre / type (catégories littéraires ou discursives)', 'genres')}
            {this.renderLine('Mention de genre autodéclarée', 'genre_declare')}
            {this.renderList('Caractéristiques médiatiques', 'caracteristiques', 'autre_caracteristique')}
            {this.renderList('Types d\'interactions', 'interactions', 'autre_interaction')}


            {this.state.oeuvre.extraits.length + this.state.oeuvre.citations.length > 0 ?
              <div>
                <SectionTitle>Extraits visuels et multimédiatiques</SectionTitle>

                {this.state.oeuvre.extraits.map((ex, index) =>
                  <Extrait>
                    <SubsectionTitle>Extrait #{index + 1}</SubsectionTitle>
                    <p><strong>Source</strong>: {ex.source}</p>
                    <p><strong>Créateur</strong>: {ex.createur}</p>
                    {ex.typeLien === 'page' ? <p><strong>URL</strong>: <a href={ex.url.trim()} target="blank" rel="noopener noreferrer">{ex.url.trim()}</a></p> : null}
                    {ex.typeLien === 'image' ? <ImgWrapper src={ex.url.trim()} /> : null}
                    {ex.typeLien === 'video' ? <ReactPlayer url={ex.url.trim()} controls /> : null}
                  </Extrait>
                )}

                {this.renderList(<span>Citations / passages significatifs de l'&oelig;uvre</span>, 'citations', null, true)}
              </div>
              : null}

            {this.state.oeuvre.personnesConsultees.length + this.state.oeuvre.articlesPresse.length + this.state.oeuvre.articles_livres.length + this.state.oeuvre.conferences.length + this.state.oeuvre.liensRepertoires.length + this.state.oeuvre.reeditions.length + this.state.oeuvre.autres.length > 0 ?
              <div>
                <SectionTitle>Réception et sources</SectionTitle>
                {this.renderList('Personnes consultées', 'personnesConsultees', null, true)}
                {this.renderList('Articles de presse', 'articlesPresse', null, true)}
                {this.renderList('Articles scientifiques ou livre', 'articles_livres', null, true)}
                {this.renderList('Conférences/communications à des colloques', 'conferences', null, true)}
                {this.renderList(<span>Liens vers d'autres bases de données ou répertoires d'&oelig;uvres numériques</span>, 'liensRepertoires', null, true)}
                {this.renderList(<span>Rééditions de l'&oelig;uvre ou d'extraits de l'&oelig;uvre dans les anthologies, collections ou numéros spéciaux</span>, 'reeditions', null, true)}
                {this.renderList('Autres', 'autres', null, true)}
              </div>
              : null}

            <ButtonWrapper><Link to={`/`}><button type="button" className="btn btn-dark">Retour</button></Link></ButtonWrapper>
          </div>
        }
      </div>
    )
  }
}

export default Fiche
