import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import { NavBar } from '../components'
import { Consultation, Fiche, OeuvresList, OeuvresInsert, OeuvresUpdate } from '../pages'
import api from '../api'

import 'bootstrap/dist/css/bootstrap.min.css'

class App extends Component {
    constructor(props) {
        super(props)

        this.state = { isLoaded: false, isLoggedIn: false }
    }

    componentDidMount = async () => {
        await api.getSession().then(res => {
            this.setState({
                isLoaded: true,
                isLoggedIn: res.data.loggedIn
            })
        })
    }

    doLogin = async () => {
    	let payload = {
    	    username: document.getElementById('loginUserName').value,
    	    password: document.getElementById('loginPassword').value
    	}

        await api.login(payload).then(res => {
            this.setState({ isLoggedIn: res.data.success })
        }).catch(error => {
            window.alert(`Nom d'utilisateur ou mot de passe invalide.`)
        })
    }

    doLogout = async () => {
        await api.logout().then(res => {
            if (res.data.loggedOut) {
                this.setState({ isLoggedIn: false })
            }
        })
    }

    render() {
      if (!this.state.isLoaded) return null;

      return (
        <Router>
            <NavBar isLoggedIn={this.state.isLoggedIn} doLogin={() => this.doLogin()} doLogout={() => this.doLogout()} />
            <Switch>
                <Route path="/" exact component={Consultation} />
                <Route path="/fiche/:id" exact component={(props) => <Fiche {...props} editButton={this.state.isLoggedIn} />} />
                {this.state.isLoggedIn ?
                  <React.Fragment>
                    <Route path="/oeuvres/list" exact component={OeuvresList} />
                    <Route path="/oeuvres/create" exact component={OeuvresInsert} />
                    <Route
                      path="/oeuvres/update/:id"
                      exact
                      component={OeuvresUpdate}
                    />
                  </React.Fragment>
                : <Route render={() => <Redirect to={{pathname: "/"}} />} />}
            </Switch>
        </Router>
      )
    }
}

export default App
