import axios from 'axios'

const api = axios.create({
    baseURL: '/backapi',
})

export const insertOeuvre = payload => api.post(`/api/oeuvre`, payload)
export const getAllOeuvres = () => api.get(`/api/oeuvres`)
export const getOeuvres = (search) => api.get(`/api/search/${search}`)
export const updateOeuvreById = (id, payload) => api.put(`/api/oeuvre/${id}`, payload)
export const deleteOeuvreById = id => api.delete(`/api/oeuvre/${id}`)
export const getOeuvreById = id => api.get(`/api/oeuvre/${id}`)

export const login = payload => api.post(`/login`, payload)
export const getSession = () => api.get(`/session`)
export const logout = () => api.get(`/logout`)

const apis = {
    insertOeuvre,
    getAllOeuvres,
    getOeuvres,
    updateOeuvreById,
    deleteOeuvreById,
    getOeuvreById,
    login,
    getSession,
    logout,
}

export default apis
