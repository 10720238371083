import React, { Component } from 'react'
import styled from 'styled-components'
// eslint-disable-next-line
import { BrowserRouter as Router, Link } from 'react-router-dom'

const Title = styled.h1.attrs({
    className: 'h1',
})``

const Subtitle = styled.h3.attrs({
    className: 'h3',
})`
    margin: 30px 0 10px 5px;
`

const Subsubtitle = styled.h4.attrs({
    className: 'h4',
})`
    margin: 20px 0 5px 5px;
`


const Wrapper = styled.div.attrs({
    className: 'form-group',
})`
    margin: 0 auto;
    max-width: 50em;
`

const Label = styled.label`
    margin: 5px;
`

const InputText = styled.input.attrs({
    className: 'form-control',
})`
    margin: 5px;
`

const InputTextArea = styled.textarea.attrs({
    className: 'form-control',
})`
    margin: 5px;
`

const InputSelect = styled.select.attrs({
    className: 'form-control',
})`
    margin: 5px;
`

const Button = styled.button.attrs({
    className: `btn btn-primary`,
})`
    margin: 15px 15px 15px 5px;
`

const CancelButton = styled.a.attrs({
    className: `btn btn-danger`,
})`
    margin: 15px 15px 15px 5px;
`

const ListeLangues = ['Français', 'Anglais']

const ListeSupports = ['Application - console',
'Application - ordinateur',
'Application - tablette',
'Application - smartphone',
'Application - smartwatch',
'Cassette VHS',
'Cédérom',
'Disquette 3,5 pouces',
'Disquette 5,25 pouces',
'Disquette 8 pouces',
'Document',
'DVD',
'Évènement',
'Installation / exposition',
'Objet physique',
'Papier',
'Performance',
'Streaming',
'Web']

const ListeGenres = ['adaptation',
'album',
'aphorismes',
'archive',
'autofiction',
'bande dessinée',
'chanson',
'chronique',
'collectif',
'conte / fable',
'essai',
'fanfiction',
'feuilleton / série',
'fiction',
'fragments',
'jeu',
'journal intime',
'hypertexte',
'installation',
'lettrisme',
'livre',
'non fiction',
'nouvelle',
'oeuvre narrative',
'philosophie',
'poésie',
'recueil',
'récit',
'reportage',
'roman',
'théâtre']

const ListeCaracteristiques = ['animation',
'architecture',
'enregistrement de voix',
'environnement 3D',
'géolocalisation / géonarration',
'illustration',
'images animées',
'musique',
'peinture',
'photographies',
'réalité augmentée',
'réalité virtuelle',
'sculpture',
'son',
'texte',
'vidéo']

const ListeInteractions = [
'activation de contenu',
'déplacement / glissement de contenu',
'déplacement physique',
'interaction app. num. / app. num.',
'interaction livre / appareil numérique',
'manipulation du livre papier',
'navigation 3D - première personne',
'navigation 3D - troisième personne',
'navigation à choix multiples',
'navigation libre',
'navigation linéaire',
'participatif de contenu',
'rotation appareil']

const ListeExtraits = [
'archives du web',
'capture d\'écran',
'image dans l\'oeuvre',
'navigation filmée',
'entretien avec l’auteur.e']

class SelectWithOther extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOther: props.options.indexOf(props.value) === -1
    }
  }

  render() {
    return (
        <div>
            <Label>{this.props.label}: </Label>
            <InputSelect
                lang="fr-CA"
                value={this.state.isOther ? 'Autre' : this.props.value}
                onChange={(e) => {this.setState({isOther: e.target.value === 'Autre'}); this.props.onChange(e.target.value === 'Autre' ? '' : e.target.value);}}>
                {this.props.options.map((v, index) => <option key={index}>{v}</option>)}
                <option>Autre</option>
            </InputSelect>
            {this.state.isOther ?
                <div>
                    <Label>{this.props.labelOther}: </Label>
                    <InputText
                        type="text"
                        lang="fr-CA"
                        value={this.props.value}
                        onChange={(e) => this.props.onChange(e.target.value)}
                    />
                </div>
             : null}
         </div>
    );
  }
}

function TextInputs(props) {
  let handleValueChange = idx => evt => {
    const newValues = props.values;
    newValues[idx] = evt.target.value;

    props.changedHandler(props.name, newValues);
  };

  let handleAddValue = () => {
    props.changedHandler(props.name, props.values.concat([""]));
  };

  let handleRemoveValue = idx => () => {
    props.changedHandler(props.name, props.values.filter((s, vidx) => idx !== vidx));
  };

  return (
    <div>
      <Label>{props.label}: {props.mandatory ? '(*)' : ''}</Label>

      {props.values.map((value, idx) => (
        <div key={idx}>
          <input
            type="text"
            value={value}
            className="form-control"
            style={{width: '85%', display: 'inline-block'}}
            onChange={handleValueChange(idx)}
          />
          <CancelButton style={{color: 'white'}} onClick={handleRemoveValue(idx)}>&ndash;</CancelButton>
        </div>
      ))}
      <Button onClick={handleAddValue}>Ajouter</Button>
    </div>
  );
}

function Extraits(props) {
  let handleValueChange = idx => evt => {
    const newValues = props.values;
    newValues[idx][evt.target.name] = evt.target.value;

    props.changedHandler(props.name, newValues);
  };

  let handleTypeChange = idx => val => {
    const newValues = props.values;
    newValues[idx].type = val;

    props.changedHandler(props.name, newValues);
  };

  let handleAddValue = () => {
    props.changedHandler(props.name, props.values.concat([{type: '', source: '', typeLien: 'page'}]));
  };

  let handleRemoveValue = idx => () => {
    props.changedHandler(props.name, props.values.filter((s, vidx) => idx !== vidx));
  };

  return (
    <div>
      {props.values.map((ex, idx) => (
        <div key={idx}>
          <Subsubtitle>Extrait #{idx+1}</Subsubtitle>
          <SelectWithOther
            options={ListeExtraits}
            label="Type d'extrait"
            value={ex.type}
            labelOther="Spécifier le type"
            onChange={handleTypeChange(idx)}
          />
          <Label>Source de l'extrait: (*)</Label>
          <InputText
              type="text"
              lang="fr-CA"
              name="source"
              value={ex.source}
              onChange={handleValueChange(idx)}
          />
          <Label>Créateur de l'extrait: (*)</Label>
          <InputText
              type="text"
              lang="fr-CA"
              name="createur"
              value={ex.createur}
              onChange={handleValueChange(idx)}
          />

          <Label>Type d'URL: </Label>
          <InputSelect
              lang="fr-CA"
              name="typeLien"
              value={ex.typeLien}
              onChange={handleValueChange(idx)}>
              <option value="page">Page web / téléchargement</option>
              <option value="image">Image</option>
              <option value="video">Vidéo</option>
          </InputSelect>

          <Label>URL: (*)</Label>
          <InputText
              type="text"
              lang="fr-CA"
              name="url"
              value={ex.url}
              onChange={handleValueChange(idx)}
          />

          <CancelButton style={{color: 'white'}} onClick={handleRemoveValue(idx)}>Supprimer cet extrait</CancelButton>
        </div>
      ))}
      <Button onClick={handleAddValue}>Ajouter un extrait</Button>
    </div>
  );
}

function CheckboxList(props) {
    return (
        <div>
            <Label>{props.label}: {props.mandatory ? '(*)' : ''}</Label>
            {props.options.map((v, index) => <div key={index}><label><input type="checkbox" checked={props.withDetails ? props.values.findIndex(o => o.key === v) !== -1 : props.values.indexOf(v) !== -1} style={{margin: '5px 10px'}} onChange={(e) => props.changedHandler(props.name, e.target.checked ? props.values.concat(props.withDetails ? [{key: v}] : [v]) : props.values.filter(val => props.withDetails ? val.key !== undefined && val.key !== v : val !== v))} />{v}</label>{props.withDetails && props.values.findIndex(o => o.key === v) !== -1 ? <span style={{marginLeft: '2em'}}><label>Précisions:&nbsp;<input type="text" lang="fr-CA" value={props.values[props.values.findIndex(o => o.key === v)].details} onChange={(e) => { let newArray = props.values; newArray[props.values.findIndex(o => o.key === v)].details = e.target.value; props.changedHandler(props.name, newArray)}} /></label></span> : null}</div>)}
        </div>
    );
}

function OeuvreForm(props) {
  return (
    <Wrapper>
        <Title>{props.title}</Title>

        <Label>Date de création de la fiche: (*)</Label>
        <InputText
            type="text"
            lang="fr-CA"
            name="dateCreation"
            value={props.oeuvre.dateCreation.substr(0,10)}
            maxLength="10"
            placeholder="AAAA-MM-DD"
            onChange={props.changeHandler}
        />

        {props.oeuvre.updatedAt !== undefined ?
        <div>
            <Label>Date de mise à jour de la fiche: </Label>
            <p><Label>{new Date(props.oeuvre.updatedAt).toISOString().split('T')[0]}</Label></p>
        </div>
        : null}

        <Label>Nom du créateur et des collaborateurs de la fiche: (*)</Label>
        <InputText
            type="text"
            lang="fr-CA"
            name="createur"
            value={props.oeuvre.createur}
            onChange={props.changeHandler}
        />

        <Subtitle>Identification de l'&oelig;uvre</Subtitle>

        <Label>Titre: (*)</Label>
        <InputText
            type="text"
            lang="fr-CA"
            name="titre"
            value={props.oeuvre.titre}
            onChange={props.changeHandler}
        />

        <Label>Auteur / créateur: (*)</Label>
        <InputText
            type="text"
            lang="fr-CA"
            name="auteur"
            value={props.oeuvre.auteur}
            onChange={props.changeHandler}
        />

        <Label>Autres contributeurs (traducteur, graphiste, concepteur technique...): </Label>
        <InputText
            type="text"
            lang="fr-CA"
            name="autres_contributeurs"
            value={props.oeuvre.autres_contributeurs}
            onChange={props.changeHandler}
        />

        <Subtitle>Informations éditoriales</Subtitle>

        <CheckboxList
            options={ListeLangues}
            label="Langue(s)"
            name="langues"
            values={props.oeuvre.langues}
            changedHandler={props.arrayChangeHandler}
            mandatory
        />

        <TextInputs
            label="Autres langues"
            name="autres_langues"
            values={props.oeuvre.autres_langues}
            changedHandler={props.arrayChangeHandler}
        />

        <Label>Éditeur: </Label>
        <InputText
            type="text"
            lang="fr-CA"
            name="editeur"
            value={props.oeuvre.editeur}
            onChange={props.changeHandler}
        />

        <Label>Série (l'&oelig;uvre fait partie d’une série d'&oelig;uvres): </Label>
        <InputText
            type="text"
            lang="fr-CA"
            name="serie"
            value={props.oeuvre.serie}
            onChange={props.changeHandler}
        />

	<CheckboxList
            options={ListeSupports}
            label="Support"
            name="support"
            values={props.oeuvre.support}
            changedHandler={props.arrayChangeHandler}
            mandatory
        />

        <Label>Autre support: </Label>
        <InputText
            type="text"
            lang="fr-CA"
            name="autre_support"
            value={props.oeuvre.autre_support}
            onChange={props.changeHandler}
        />

        <Label>Précisions sur le support: </Label>
        <InputText
            type="text"
            lang="fr-CA"
            name="precisions_support"
            value={props.oeuvre.precisions_support}
            onChange={props.changeHandler}
        />

	<Label>Technologies utilisées et coquille logiciel (logiciels, systèmes d’exploitation, langages de programmation, plateformes): </Label>
        <InputText
            type="text"
            lang="fr-CA"
            name="technologies"
            value={props.oeuvre.technologies}
            onChange={props.changeHandler}
        />

	<Label>Distribution: </Label>
        <InputText
            type="text"
            lang="fr-CA"
            name="distribution"
            value={props.oeuvre.distribution}
            onChange={props.changeHandler}
        />

	<Label>Licence (domaine public, GPL, Apache, cc, cc / share, all rights reserved) : </Label>
        <InputText
            type="text"
            lang="fr-CA"
            name="licence"
            value={props.oeuvre.licence}
            onChange={props.changeHandler}
        />


        <TextInputs
            label="Date de la première publication / date de l’événement / date de publications successives s’il y a lieu (dont la dernière mise à jour connue)"
            name="datePublication"
            values={props.oeuvre.datePublication}
            changedHandler={props.arrayChangeHandler}
            mandatory
        />

        <Label>Date de disparition / d'obsolescence (ex. disparition d'une plate-forme): </Label>
        <InputText
            type="text"
            lang="fr-CA"
            name="dateDisparition"
            value={props.oeuvre.dateDisparition}
            onChange={props.changeHandler}
        />

        <Label>URL de consultation / téléchargement: </Label>
        <InputText
            type="text"
            lang="fr-CA"
            name="urlConsultation"
            value={props.oeuvre.urlConsultation}
            onChange={props.changeHandler}
        />

        <Label>URL d'archivage (oeuvres disparues): </Label>
        <InputText
            type="text"
            lang="fr-CA"
            name="urlArchivage"
            value={props.oeuvre.urlArchivage}
            onChange={props.changeHandler}
        />

        <Subtitle>Contenu et forme de l’&oelig;uvre</Subtitle>

        <Label>Description existante (citation pertinente qui donne un aperçu de l'&oelig;uvre) : </Label>
        <InputTextArea
            type="text"
            lang="fr-CA"
            name="descriptionExistante"
            rows="8"
            value={props.oeuvre.descriptionExistante}
            onChange={props.changeHandler}
        />

        <Label>Ma description : </Label>
        <InputTextArea
            type="text"
            lang="fr-CA"
            name="maDescription"
            rows="8"
            value={props.oeuvre.maDescription}
            onChange={props.changeHandler}
        />

        <Label>Commentaire : </Label>
        <InputTextArea
            type="text"
            lang="fr-CA"
            name="commentaire"
            rows="4"
            value={props.oeuvre.commentaire}
            onChange={props.changeHandler}
        />

        <Label>Principaux thèmes: </Label>
        <InputText
            type="text"
            lang="fr-CA"
            name="themes"
            value={props.oeuvre.themes}
            onChange={props.changeHandler}
        />

	<CheckboxList
            options={ListeGenres}
            label="Genre / type (catégories littéraires ou discursives)"
            name="genres"
            values={props.oeuvre.genres}
            changedHandler={props.arrayChangeHandler}
            withDetails
            mandatory
        />

        <Label>S'il y a présence d'une mention de genre autodéclarée ou plus précise (dans l'&oelig;uvre et son paratexte), l'indiquer ici: </Label>
        <InputText
            type="text"
            lang="fr-CA"
            name="genre_declare"
            value={props.oeuvre.genre_declare}
            onChange={props.changeHandler}
        />

        <CheckboxList
            options={ListeCaracteristiques}
            label="Caractéristiques médiatiques"
            name="caracteristiques"
            values={props.oeuvre.caracteristiques}
            changedHandler={props.arrayChangeHandler}
            withDetails
            mandatory
        />

        <Label>Autre caractéristique médiatique: </Label>
        <InputText
            type="text"
            lang="fr-CA"
            name="autre_caracteristique"
            value={props.oeuvre.autre_caracteristique}
            onChange={props.changeHandler}
        />

        <CheckboxList
            options={ListeInteractions}
            label="Types d'interactions"
            name="interactions"
            values={props.oeuvre.interactions}
            changedHandler={props.arrayChangeHandler}
            withDetails
            mandatory
        />

        <Label>Autre interaction: </Label>
        <InputText
            type="text"
            lang="fr-CA"
            name="autre_interaction"
            value={props.oeuvre.autre_interaction}
            onChange={props.changeHandler}
        />

        <Subtitle>Extraits visuels et multimédiatiques</Subtitle>

        <Extraits
            name="extraits"
            values={props.oeuvre.extraits}
            changedHandler={props.arrayChangeHandler}
        />
        <TextInputs
            label="Citations / passages significatifs de l'&oelig;uvre"
            name="citations"
            values={props.oeuvre.citations}
            changedHandler={props.arrayChangeHandler}
        />

        <Subtitle>Réception et sources</Subtitle>

        <TextInputs
            label="Personnes consultées"
            name="personnesConsultees"
            values={props.oeuvre.personnesConsultees}
            changedHandler={props.arrayChangeHandler}
        />

        <TextInputs
            label="Articles de presse"
            name="articlesPresse"
            values={props.oeuvre.articlesPresse}
            changedHandler={props.arrayChangeHandler}
        />

        <TextInputs
            label="Articles scientifiques ou livres"
            name="articles_livres"
            values={props.oeuvre.articles_livres}
            changedHandler={props.arrayChangeHandler}
        />

        <TextInputs
            label="Conférences/communications à des colloques"
            name="conferences"
            values={props.oeuvre.conferences}
            changedHandler={props.arrayChangeHandler}
        />

        <TextInputs
            label="Liens vers d'autres bases de données ou répertoires d'&oelig;uvres numériques (NT2, ELMCIP database, etc.)"
            name="liensRepertoires"
            values={props.oeuvre.liensRepertoires}
            changedHandler={props.arrayChangeHandler}
        />

        <TextInputs
            label="Rééditions de l'oeuvre ou d'extraits de l'&oelig;uvre dans les anthologies, collections ou numéros spéciaux"
            name="reeditions"
            values={props.oeuvre.reeditions}
            changedHandler={props.arrayChangeHandler}
        />

        <TextInputs
            label="Autres (notes sur la démarche...)"
            name="autres"
            values={props.oeuvre.autres}
            changedHandler={props.arrayChangeHandler}
        />

	<p>&nbsp;</p>

        <Button onClick={props.onSubmit}>{props.submitButtonText}</Button>
        <Link className="btn btn-danger" to="/oeuvres/list">Annuler</Link>
    </Wrapper>
  );
}
export default OeuvreForm
